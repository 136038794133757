import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordsMatchValidator, passwordStrengthValidator } from '@revolve-app/app/core/utils/validators';

@Injectable()
export class AuthForm {
  phoneNumberValidator = '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})';
  emailValidator = `/^[a-zA-Z0-9. _%+-]+@[a-zA-Z0-9. -]+\\. [a-zA-Z]{2,}$`;
  constructor(private fb: FormBuilder) {}

  signupForm(): FormGroup {
    return this.fb.group({
      displayName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneNumberValidator)]],
      // email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, passwordStrengthValidator]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
    }, { validators: passwordsMatchValidator });
  }

  signInForm(): FormGroup {
    return this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneNumberValidator)]],
      // email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  forgotPasswordStep1Form(): FormGroup {
    return this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneNumberValidator)]],
      // email: ['', [Validators.required, Validators.pattern(this.emailValidator)]],
    });
  }

  forgotPasswordSetNewPasswordForm(): FormGroup {
    return this.fb.group({
      code: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneNumberValidator)]],
      password: ['', [Validators.required, passwordStrengthValidator]],
      confirmPassword: ['', Validators.required],
    }, { validators: passwordsMatchValidator });
  }

  verificationForm(): FormGroup {
    return this.fb.group({
      //email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneNumberValidator)]],
      tempCode: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

}
